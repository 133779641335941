<template>
    <div class="notify-config">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <b-container fluid class="base-color">
            <div class="p-3"></div>
            <b-card no-body>
                <b-tabs card fill>
                    <!-- ===================================== -->
                    <b-tab title="通知設定" class="px-0">
                        <div class="m-1">
                            <b-table :items="tblTraps" :fields="fields" head-variant="dark" responsive hover bordered>
                                <template v-slot:cell(notify_capture)="data">
                                    <b-form-checkbox switch size="lg" v-model="data.item.notify_capture" class="my-2" @change="axiosPutUserConfigs(data)"></b-form-checkbox>
                                </template>
                                <template v-slot:cell(notify_battery)="data">
                                    <b-form-checkbox switch size="lg" v-model="data.item.notify_battery" class="my-2" @change="axiosPutUserConfigs(data)"></b-form-checkbox>
                                </template>
                                <template v-slot:cell(notify_not_alive)="data">
                                    <b-form-checkbox switch size="lg" v-model="data.item.notify_not_alive" class="my-2" @change="axiosPutUserConfigs(data)"></b-form-checkbox>
                                </template>
                            </b-table>
                        </div>
                    </b-tab>
                    <!-- ===================================== -->
                    <b-tab title="テスト">
                        <b-form class="text-left">
                            <b-form-group label="■ 通知タイトル">
                                <b-form-input v-model="pushSendData.title"></b-form-input>
                            </b-form-group>
                            <b-form-group label="■ 通知メッセージ">
                                <b-form-input v-model="pushSendData.body"></b-form-input>
                            </b-form-group>
                            <b-form-group label="■ デバイストークン" v-show="false">
                                <b-form-input v-model="pushSendData.push_token"></b-form-input>
                            </b-form-group>
                        </b-form>
                        <b-button @click="pushSend()" variant="success" :disabled="dbUser.device_token ? false : true">テスト通知送信</b-button>
                    </b-tab>
                    <!-- ===================================== -->
                </b-tabs>
            </b-card>
            <div class="p-5"></div>
        </b-container>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";

export default {
    name: "NotifyConfig",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: {},
            dbSite: {},
            dbUser: {},
            dbUserConfigs: [],
            allowTraps: [],
            tblTraps: [],
            dbTraps: [],
            fields: [
                { key: "name", label: "ほかパト名", sortable: true },
                { key: "notify_capture", label: "捕獲", sortable: true },
                { key: "notify_battery", label: "バッテリ低", sortable: true },
                { key: "notify_not_alive", label: "異常", sortable: true }
            ],
            pushSendData: {
                is_sent: 0,
                trap_id: "",
                trap_name: "",
                user_id: "",
                user_name: "",
                push_device: "",
                push_token: "",
                triggerd: "T",
                title: "",
                body: "",
                modified_at: null
            },
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        //await new Promise(r => setTimeout(r, 300));
        await this.axiosGetTraps();
        await this.axiosGetUserConfigs();
        this.initPushSendData();
        this.isLoading = false;
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        // async axiosGetUserConfigs() {
        //     await this.axios({
        //         method: "GET",
        //         url: "/web/api/user_configs",
        //         headers: { Authorization: "Bearer " + this.jwt },
        //         timeout: 30000
        //     }).then(response => {
        //         this.allowTraps = [];
        //         if (response.data.json) {
        //             this.dbUserConfigs = response.data.json;
        //             for (let i in this.dbUserConfigs) {
        //                 if (this.dbUserConfigs[i].allow_view == "1") {
        //                     this.allowTraps.push(this.dbUserConfigs[i].trap_id);
        //                 }
        //             }
        //         }
        //     });
        // },
        //====================================================
        async axiosGetTraps() {
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                headers: { Authorization: "Bearer " + this.jwt },
                params: {
                    //"id[IN]": this.allowTraps.join(","),
                    is_active: "1"
                },
                timeout: 30000
            }).then(response => {
                if (response.data.json) {
                    this.dbTraps = response.data.json;
                }
            });
        },
        trapName(trap_id) {
            let ret = "";
            for (let i in this.dbTraps) {
                if (trap_id == this.dbTraps[i].id) ret = this.dbTraps[i].name;
            }
            return ret;
        },
        //====================================================
        async axiosGetUserConfigs() {
            const axiosParams = {
                user_id: this.myInfo.id,
                allow_view: 1,
                "_order[trap_id]": "asc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/user_configs",
                headers: { Authorization: "Bearer " + this.jwt },
                params: axiosParams,
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUserConfigs = response.data.json;
                    this.tblTraps = [];
                    for (let i in this.dbUserConfigs) {
                        let rec = this.dbUserConfigs[i];
                        this.tblTraps.push({
                            id: rec.id,
                            name: this.trapName(rec.trap_id),
                            notify_capture: rec.notify_capture ? true : false,
                            notify_battery: rec.notify_battery ? true : false,
                            notify_not_alive: rec.notify_not_alive ? true : false
                        });
                    }
                }
            });
        },
        //====================================================
        // 通知フラグの更新（スイッチOnOffで即時Put）
        //====================================================
        axiosPutUserConfigs(data) {
            self.console.log(data);
            const id = data.item.id;
            const fld = data.field.key;
            const val = data.value ? 1 : 0;
            let axiosParams = {};
            if (fld == "notify_capture") axiosParams = { notify_capture: val };
            if (fld == "notify_battery") axiosParams = { notify_battery: val };
            if (fld == "notify_not_alive") axiosParams = { notify_not_alive: val };
            this.axios({
                method: "PUT",
                url: "/web/api/user_configs/" + id,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosParams,
                timeout: 30000
            });
        },
        //====================================================
        // 通知設定の保存
        //====================================================
        // async axiosPostUserConfigs() {
        //     const axiosParams = [];
        //     for (let i in this.tblTraps) {
        //         axiosParams.push({
        //             user_id: this.myInfo.id,
        //             trap_id: this.tblTraps[i].id,
        //             allow_view: 1,
        //             notify_capture: this.tblTraps[i].notify_capture ? 1 : 0,
        //             notify_battery: this.tblTraps[i].notify_battery ? 1 : 0,
        //             notify_not_alive: this.tblTraps[i].notify_not_alive ? 1 : 0,
        //             modified_at: this.$moment().format("YYYY-MM-DD HH:mm:ss")
        //         });
        //     }
        //     await this.axios({
        //         method: "POST",
        //         url: "/web/api/user_configs",
        //         headers: { Authorization: "Bearer " + this.jwt },
        //         data: axiosParams,
        //         timeout: 30000
        //     }).then(response => {
        //         if (response.data.json) this.axiosGetUserConfigs();
        //     });
        // },
        //====================================================
        // プッシュ通知テスト
        //====================================================
        initPushSendData() {
            this.pushSendData = {
                is_sent: 0,
                trap_id: "0",
                trap_name: "テスト",
                user_id: this.myInfo.id,
                user_name: this.myInfo.name,
                push_device: this.dbUser.device,
                push_token: this.dbUser.device_token,
                triggered: "-",
                title: "ほかパト○○機：捕獲検出!!",
                body: "テスト、テスト、テスト",
                modified_at: null
            };
        },
        //====================================================
        // プッシュ＆メール通知テスト
        //====================================================
        pushSend() {
            //----------------------------------
            if (this.dbUser.email.trim()) {
                let axiosData = {
                    is_sent: 0,
                    trap_id: this.pushSendData.trap_id,
                    trap_name: this.pushSendData.trap_name,
                    user_id: this.dbUser.id,
                    user_name: this.dbUser.name,
                    email: this.dbUser.email,
                    triggered: "T",
                    title: this.pushSendData.title,
                    body: this.pushSendData.body
                };
                this.axios({
                    method: "POST",
                    url: "/web/api/mail_logs",
                    headers: { Authorization: "Bearer " + this.jwt },
                    data: axiosData,
                    timeout: 30000
                }).then(response => {
                    self.console.log(response.data.json);
                    alert("メール送信しました。届かない場合は「迷惑メール」をご確認下さい。");
                });
            }
            //----------------------------------
            if (this.dbUser.device_token.trim()) {
                let axiosData = JSON.parse(JSON.stringify(this.pushSendData));
                axiosData.modified_at = this.$moment().format("YYYY-MM-DD HH:mm:ss");
                self.console.log(axiosData);
                this.axios({
                    method: "POST",
                    url: "/web/api/push_logs",
                    headers: { Authorization: "Bearer " + this.jwt },
                    data: axiosData,
                    timeout: 30000
                }).then(response => {
                    self.console.log(response.data.json);
                    alert("プッシュ登録しました。約10秒後に送信されます。");
                });
            }
            //----------------------------------
        }
        //====================================================
    }
};
</script>
