<template>
    <div class="login">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <div class="pt-5"></div>
            <div class="pt-5"></div>
            <div class="m-auto" style="width: 200px;">
                <b-img :src="LogoImg" width="200"></b-img>
            </div>
            <div class="m-3"></div>
            <b-form>
                <b-input-group class="mb-3">
                    <b-form-input type="text" v-model="username" placeholder="アカウント" />
                </b-input-group>
                <b-input-group class="mb-3">
                    <b-form-input type="password" v-model="password" placeholder="パスワード" />
                </b-input-group>
                <b-button block variant="primary" @click="submitUserLogin()">ログイン</b-button>
            </b-form>
            <h6 class="text-right mt-3">Ver {{ ver }}</h6>
        </b-container>
        <!-- =============================================== -->
    </div>
</template>

<script>
import Version from "@/version.json";
export default {
    name: "Login",
    //==========================================
    data() {
        return {
            ver: Version.version,
            dbVersion: [],
            LogoImg: require("@/assets/logo.png"),
            phone: "",
            username: "",
            password: "",
            dbUsers: [],
            isLoading: false
        };
    },
    //==========================================
    created: async function() {
        if (this.$route.query.id && this.$route.query.pw) {
            this.username = this.$route.query.id;
            this.password = this.$route.query.pw;
            //self.console.log("id =", this.$route.query.id);
            //self.console.log("pw =", this.$route.query.pw);
            this.submitUserLogin();
        } else {
            this.username = this.$localStorage.get("username");
            this.password = this.$localStorage.get("password");
            this.isLoading = true;
            await new Promise(r => setTimeout(r, 1000));
            this.isLoading = false;
        }
        //-----------------------------------------
        // Android版の岸さんアプリでは、カメラダイレクト撮影ができない。
        // そのため、カメラボタンを非表示にするフラグをLocalStrageに保存する。
        // 「ほかく報告」「もくげき投稿」は、このフラグによりカメラボタンの表示/非表示を切り替える。
        //-----------------------------------------
        if (this.$route.query.camera) {
            this.$localStorage.set("browser-option", JSON.stringify({ camera: "off" }));
        } else {
            this.$localStorage.remove("browser-option");
        }
        //-----------------------------------------
    },
    //==========================================
    mounted: function() {
        this.getVersion();
    },
    //==========================================
    methods: {
        //-----------------------------------------
        getVersion() {
            this.axios({
                method: "GET",
                url: "/web/versions",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbVersion = response.data.json[0];
                if (this.dbVersion.user) {
                    if (this.dbVersion.user > this.ver) {
                        if (confirm("新しいバージョンがあります。\n更新しますか？")) {
                            this.reloadApp();
                        }
                    }
                }
            });
        },
        //-----------------------------------------
        // バージョンアップ（サービスワーカーを解除し、リロードで再登録する）
        async reloadApp() {
            window.navigator.serviceWorker.getRegistrations().then(registrations => {
                for (let registration of registrations) registration.unregister();
            });
            await new Promise(r => setTimeout(r, 1000));
            window.location.reload();
            await new Promise(r => setTimeout(r, 1000));
            alert("更新しました。\n再起動して下さい。");
        },
        //-----------------------------------------
        submitUserLogin() {
            this.username = this.username.trim();
            this.password = this.password.trim();
            this.axios({
                method: "POST",
                url: "/web/api/authenticate",
                data: {
                    username: this.username,
                    password: this.password
                },
                timeout: 3000 // 3000ms
            })
                .then(response => {
                    let jwt = response.data.token;
                    this.dbUsers = response.data.json;
                    if (jwt) {
                        this.$localStorage.set("user-jwt", jwt);
                        this.$localStorage.set("username", this.username);
                        this.$localStorage.set("password", this.password);
                        this.$router.push({ path: "/" });
                    } else {
                        alert("登録がありません。");
                    }
                })
                .catch(error => {
                    alert("認証エラー");
                    self.console.log(error);
                });
        }
    }
    //==========================================
};
// alter table sites add column `loginid` varchar(80) NOT NULL after name;
// alter table sites add column `loginpw` varchar(80) NOT NULL after loginid;
// alter table sites add column `user_max` int(10) unsigned NOT NULL DEFAULT 5 after city;

// update sites set loginid = uuid() ;
// alter table sites add UNIQUE `loginid` (`loginid`);

/* ------------------------------------
CREATE TABLE `versions` (
  `id` int(10) unsigned NOT NULL AUTO_INCREMENT,
  `admin` varchar(80) NOT NULL DEFAULT '',
  `mgr` varchar(80) NOT NULL DEFAULT '',
  `user` varchar(80) NOT NULL DEFAULT '',
  PRIMARY KEY (`id`)
);
insert into versions values (1, '20211108', '20211108', '20211108');
------------------------------------- */
</script>
