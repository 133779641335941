<template>
    <div class="shot">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <b-container fluid class="base-color">
            <b-card no-body class="m-1">
                <b-tabs card justified>
                    <!-- ================= -->
                    <b-tab title="写真">
                        <div class="mt-3">
                            <b-form>
                                <b-row>
                                    <b-col class="text-center" v-show="dispCameraBtn">
                                        <label>
                                            <b-icon icon="camera" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file ref="camera" accept="image/*" capture @change="onFileChange" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                    <b-col class="text-center">
                                        <label>
                                            <b-icon icon="image" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file accept="image/*" @change="onFileChange" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div class="m-0">
                                <!-- <b-card class="round-card"></b-card>
                                -->
                                <b-card no-body>
                                    <b-aspect :aspect="aspect" style="height: 300px;">
                                        <b-img fluid :src="uploadedImage" style="height: 300px;" />
                                    </b-aspect>
                                </b-card>
                            </div>
                        </div>
                    </b-tab>
                    <!-- ================= -->
                    <b-tab title="場所" @click="mapInvalidateSize()">
                        <l-map ref="map" style="height: 400px;" :zoom="zoom" :minZoom="minZoom" :center="[newMapPoint.lat, newMapPoint.lng]" @click="handleMapClick">
                            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                            <l-control-layers position="topright"></l-control-layers>
                            <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                            <l-marker :lat-lng="[newMapPoint.lat, newMapPoint.lng]"></l-marker>
                        </l-map>
                        <div>
                            <div v-show="newMapPoint.lat && newMapPoint.lng">
                                <h6 class="text-center">GPS：{{ newMapPoint.lat }}, {{ newMapPoint.lng }}</h6>
                            </div>
                            <div v-show="!newMapPoint.lat || !newMapPoint.lng">
                                <h6 class="text-center text-danger">位置情報へのアクセスを許可して下さい</h6>
                            </div>
                        </div>
                    </b-tab>
                    <!-- ================= -->
                    <b-tab title="詳細" @click="submitEnabled = newMapPoint.lat && newMapPoint.lng ? true : false">
                        <b-card no-body class="mb-2 p-2">
                            <b-form class="text-left font-weight-bold">
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.point_type" :options="pointTypesOptions" :state="newMapPoint.point_type ? true : false" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.species" :options="speciesOptions" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.headcount" :options="headcountOptions" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <!--
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.damage" :options="damagesOptions" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.gender" :options="genderOptions" :state="newMapPoint.gender ? true : false" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.village" :options="villagesOptions" :state="newMapPoint.village ? true : false" size="lg"></b-form-select>
                                </b-form-group>
                                -->

                                <!-- ============================================= -->
                                <b-form-group v-show="questions[0]">
                                    <b-form-select v-model="newMapPoint.qa1" :options="qa1Options" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group v-show="questions[1].length">
                                    <b-form-select v-model="newMapPoint.qa2" :options="qa2Options" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group v-show="questions[2].length">
                                    <b-form-select v-model="newMapPoint.qa3" :options="qa3Options" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group v-show="questions[3].length">
                                    <b-form-select v-model="newMapPoint.qa4" :options="qa4Options" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group v-show="questions[4].length">
                                    <b-form-select v-model="newMapPoint.qa5" :options="qa5Options" :state="true" size="lg"></b-form-select>
                                </b-form-group>
                                <!-- ============================================= -->
                                <b-form-group>
                                    <b-form-input v-model="newMapPoint.title" size="lg" placeholder="メモ"></b-form-input>
                                </b-form-group>
                            </b-form>
                        </b-card>
                    </b-tab>
                    <!-- ================= -->
                </b-tabs>
            </b-card>
            <!-- ================= -->
            <div class="mt-3">
                <b-button block :disabled="!submitEnabled" size="lg" variant="warning" @click="submitModalPoint()" class="p-3 font-weight-bold" style="border-radius: 1rem;">投稿する</b-button>
            </div>
            <!-- ================= -->
            <div class="p-5">&nbsp;</div>
        </b-container>
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import { LMap, LTileLayer, LControlLayers, LControlScale, LMarker } from "vue2-leaflet";
//------------------------------------------
export default {
    name: "Shot",
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LControlScale,
        LMarker
    },
    //========================================================
    data() {
        return {
            //----------
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbPointTypes: [],
            dbSpecies: [],
            dbDamages: [],
            dbVillages: [],
            dbHeadcounts: [],
            dbQaLists: [],
            //---------------------
            dispCameraBtn: true,
            //---------------------
            submitEnabled: false,
            //---------------------
            center: { lat: 0, lng: 0 },
            zoom: 12,
            minZoom: 6,
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            uploadedImage: null,
            url: "",
            //---------------------
            // pointTypeOptions: [
            //     { text: "目撃", value: "目撃" },
            //     { text: "農作物被害", value: "農作物被害" },
            //     { text: "林業被害", value: "林業被害" }
            // ],
            pointTypesOptions: [{ text: "---", value: "---" }],
            villagesOptions: [
                { text: "---", value: "---" }
                // { text: "A地区", value: "A地区" },
                // { text: "B地区", value: "B地区" },
                // { text: "その他", value: "その他" }
            ],
            speciesOptions: [
                { text: "---", value: "---" }
                // { text: "イノシシ", value: "イノシシ" },
                // { text: "シカ", value: "シカ" },
                // { text: "その他", value: "その他" },
                // { text: "不明", value: "不明" }
            ],
            damagesOptions: [
                { text: "--- 被害種別を選択してください ---", value: "---" }
                // { text: "田んぼ", value: "田んぼ" },
                // { text: "りんご", value: "りんご" },
            ],
            genderOptions: [
                { text: "--- 性別を選択してください ---", value: "---" },
                { text: "オス", value: "オス" },
                { text: "メス", value: "メス" },
                { text: "不明", value: "不明" }
            ],
            headcountOptions: [
                { text: "---", value: "---" }
                // { text: "1頭", value: "1頭" },
                // { text: "2頭", value: "2頭" },
                // { text: "3〜5頭", value: "3〜5頭" },
                // { text: "6〜10頭", value: "6〜10頭" },
                // { text: "10頭以上", value: "10頭以上" },
                // { text: "不明", value: "不明" }
            ],
            questions: ["", "", "", "", ""],
            qa1Options: [{ text: "---", value: "---" }],
            qa2Options: [{ text: "---", value: "---" }],
            qa3Options: [{ text: "---", value: "---" }],
            qa4Options: [{ text: "---", value: "---" }],
            qa5Options: [{ text: "---", value: "---" }],
            //---------------------
            newMapPoint: {
                title: "",
                jpg_file: null,
                point_type: "",
                lat: 0,
                lng: 0,
                village: "",
                species: "",
                damage: "",
                gender: "",
                headcount: "",
                qa1: "",
                qa2: "",
                qa3: "",
                qa4: "",
                qa5: ""
            },
            //---------------------
            aspect: "16:9",
            isLoading: false
            //---------------------
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        if (this.$localStorage.get("browser-option")) {
            let opt = JSON.parse(this.$localStorage.get("browser-option"));
            if (opt.camera == "off") this.dispCameraBtn = false;
        }
        //---------------------------------
        this.isLoading = true;
        this.axiosGetSites();
        this.axiosGetPointTypes();
        this.axiosGetSpecies();
        this.axiosGetDamages();
        this.axiosGetVillages();
        this.axiosGetHeadcounts();
        this.axiosGetQaLists();
        this.isLoading = false;
        //---------------------------------
        window.scrollTo(0, 0);
        //---------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {
        //-------------------------------
        // 現在位置を取得
        //-------------------------------
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    let coords = position.coords;
                    // 緯度経度だけ取得
                    this.newMapPoint.lat = Math.floor(coords.latitude * 1000000) / 1000000;
                    this.newMapPoint.lng = Math.floor(coords.longitude * 1000000) / 1000000;
                    this.zoom = 18;
                }.bind(this),
                function(error) {
                    self.console.log(error);
                }
            );
        }
        //-------------------------------
    },
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        // 写真撮影されたら、その画像を表示
        //====================================================
        onFileChange(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.uploadedImage = URL.createObjectURL(files[0]);
            this.newMapPoint.jpg_file = files[0];
            this.submitEnabled = true;
        },
        //====================================================
        // Mapクリックで、GPS座標を補正できる
        //====================================================
        handleMapClick: function(event) {
            this.newMapPoint.lat = Math.floor(event.latlng.lat * 1000000) / 1000000;
            this.newMapPoint.lng = Math.floor(event.latlng.lng * 1000000) / 1000000;
        },
        //====================================================
        // Map領域の調整は、表示されてから
        //====================================================
        async mapInvalidateSize() {
            await new Promise(r => setTimeout(r, 100));
            const map = this.$refs.map.mapObject;
            map.invalidateSize();
        },
        //====================================================

        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) this.dbSite = response.data.json[0];
            });
        },
        //====================================================
        async axiosGetPointTypes() {
            await this.axios({
                method: "GET",
                url: "/web/api/point_types",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbPointTypes = response.data.json;
                this.pointTypesOptions = [];
                //this.pointTypesOptions.push({ text: "--- 投稿種別を選択してください ---", value: "" });
                for (let pointType of this.dbPointTypes) {
                    this.pointTypesOptions.push({ text: pointType.name, value: pointType.name });
                }
                //-------------------------------
                // 初期選択
                if (this.pointTypesOptions[0]) {
                    this.newMapPoint.point_type = this.pointTypesOptions[0]["value"];
                }
            });
        },
        //====================================================
        async axiosGetSpecies() {
            await this.axios({
                method: "GET",
                url: "/web/api/species",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSpecies = response.data.json;
                this.speciesOptions = [];
                this.speciesOptions.push({ text: "--- 獣種を選択してください ---", value: "" });
                for (let species of this.dbSpecies) {
                    this.speciesOptions.push({ text: species.name, value: species.name });
                }
            });
        },
        //====================================================
        async axiosGetDamages() {
            await this.axios({
                method: "GET",
                url: "/web/api/damages",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDamages = response.data.json;
                this.damagesOptions = [];
                this.damagesOptions.push({ text: "--- 被害種別を選択してください ---", value: "" });
                for (let item of this.dbDamages) {
                    this.damagesOptions.push({ text: item.name, value: item.name });
                }
            });
        },
        //====================================================
        async axiosGetVillages() {
            await this.axios({
                method: "GET",
                url: "/web/api/villages",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbVillages = response.data.json;
                this.villagesOptions = [];
                this.villagesOptions.push({ text: "--- 地区を選択してください ---", value: "" });
                for (let village of this.dbVillages) {
                    this.villagesOptions.push({ text: village.name, value: village.name });
                }
            });
        },
        //====================================================
        async axiosGetHeadcounts() {
            await this.axios({
                method: "GET",
                url: "/web/api/headcounts",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbHeadcounts = response.data.json;
                this.headcountOptions = [];
                this.headcountOptions.push({ text: "--- 頭数を選択してください ---", value: "" });
                for (let item of this.dbHeadcounts) {
                    this.headcountOptions.push({ text: item.name, value: item.name });
                }
            });
        },
        //====================================================
        async axiosGetQaLists() {
            await this.axios({
                method: "GET",
                url: "/web/api/qa_lists",
                params: {
                    "_order[question_no]": "asc",
                    "_order[answer_no]": "asc"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbQaLists = response.data.json;
                this.questions = ["", "", "", "", ""];
                this.qa1Options = [];
                this.qa2Options = [];
                this.qa3Options = [];
                this.qa4Options = [];
                this.qa5Options = [];
                for (let item of this.dbQaLists) {
                    if (item.qa_flg == "q") {
                        let question_no = item.question_no;
                        this.questions[question_no - 1] = item.name;
                        if (item.question_no == 1) this.qa1Options.push({ text: "--- " + item.name + " を選択してください ---", value: "" });
                        if (item.question_no == 2) this.qa2Options.push({ text: "--- " + item.name + " を選択してください ---", value: "" });
                        if (item.question_no == 3) this.qa3Options.push({ text: "--- " + item.name + " を選択してください ---", value: "" });
                        if (item.question_no == 4) this.qa4Options.push({ text: "--- " + item.name + " を選択してください ---", value: "" });
                        if (item.question_no == 5) this.qa5Options.push({ text: "--- " + item.name + " を選択してください ---", value: "" });
                    }
                }
                for (let item of this.dbQaLists) {
                    if (item.qa_flg == "a") {
                        if (item.question_no == 1) this.qa1Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 2) this.qa2Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 3) this.qa3Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 4) this.qa4Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 5) this.qa5Options.push({ text: item.name, value: item.name });
                    }
                }
            });
        },
        //====================================================

        //====================================================
        // マップポイント追加 (submit)
        //====================================================
        submitModalPoint() {
            this.submitEnabled = false;
            //-----------------------------------
            //this.$refs["modalAddCircle"].hide(); // Modal Hide
            //-----------------------------------
            // const axiosData = {
            //     site_id: this.myInfo.site_id,
            //     user_id: this.newMapPoint.user_id,
            //     user_name: this.newMapPoint.user_name,
            //     posted_at: this.newMapPoint.posted_at,
            //     title: this.newMapPoint.title.trim(),
            //     jpg_file: this.newMapPoint.jpg_file,
            //     village: this.newMapPoint.village,
            //     gps_latitude: this.newMapPoint.lat,
            //     gps_longitude: this.newMapPoint.lng,
            //     point_type: this.newMapPoint.point_type,
            //     species: this.newMapPoint.species,
            //     gender: this.newMapPoint.gender,
            //     headcount: this.newMapPoint.headcount,
            //     is_public: this.newMapPoint.is_public
            // };
            //-----------------------------------
            let formData = new FormData();
            formData.append("site_id", this.myInfo.site_id);
            formData.append("user_id", this.myInfo.id);
            formData.append("user_name", this.myInfo.name);
            formData.append("posted_at", this.$moment().format("YYYY-MM-DD HH:mm:ss"));
            formData.append("title", this.newMapPoint.title ? this.newMapPoint.title : " ");
            formData.append("jpg_file", this.newMapPoint.jpg_file);
            formData.append("gps_latitude", this.newMapPoint.lat);
            formData.append("gps_longitude", this.newMapPoint.lng);
            formData.append("point_type", this.newMapPoint.point_type);
            formData.append("species", this.newMapPoint.species);
            formData.append("damage", this.newMapPoint.damage);
            formData.append("headcount", this.newMapPoint.headcount);

            formData.append("qa1", this.newMapPoint.qa1);
            formData.append("qa2", this.newMapPoint.qa2);
            formData.append("qa3", this.newMapPoint.qa3);
            formData.append("qa4", this.newMapPoint.qa4);
            formData.append("qa5", this.newMapPoint.qa5);

            //formData.append("village", this.newMapPoint.village);
            //formData.append("gender", this.newMapPoint.gender);
            formData.append("status", "public");
            //-----------------------------------
            let restMethod = "POST";
            let restUrl = "/web/api/map_points";
            if (this.newMapPoint.id > 0) {
                restMethod = "PUT";
                restUrl = "/web/api/map_points/" + this.newMapPoint.id;
            }
            this.isLoading = true;
            this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: formData,
                timeout: 30000
            }).then(response => {
                this.isLoading = false;
                this.submitEnabled = true;
                alert("送信しました。\nご投稿ありがとうございました。");
                //self.console.log(response.data);
                if (response.data.json[0]) {
                    const record = response.data.json[0];
                    this.$router.push({
                        name: "Timeline",
                        query: {
                            pk: record.id
                            //lat: record.gps_latitude,
                            //lng: record.gps_longitude
                        }
                    });
                }
            });
        }
        //====================================================
    }
    //====================================================
};
//----------------------------------------------------
// https://qiita.com/hikach/items/655729bc1893392dfd42
// https://qiita.com/sasao3/items/527c8d7bcf9e2f3c5843
//----------------------------------------------------
</script>
