<template>
    <div class="home">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <b-container fluid class="base-color">
            <div class="p-3"></div>
            <!-- ---------------------------------------------- -->
            <div v-show="dispHunt">
                <b-button :to="{ path: '/hunt-shot' }" block variant="warning" class="text-left mb-3" v-show="dispShot"><h2>ほかく報告</h2></b-button>
                <b-button :to="{ path: '/hunt-map' }" block variant="warning" class="text-left mb-3"><h2>ほかくマップ</h2></b-button>
                <b-button :to="{ path: '/hunt-timeline' }" block variant="warning" class="text-left mb-3"><h2>ほかくリスト</h2></b-button>
                <b-button :to="{ path: '/hokapato-list' }" block variant="warning" class="text-left mb-3"><h2>ほかパト一覧</h2></b-button>
                <b-button :to="{ path: '/notified-list' }" block variant="warning" class="text-left mb-3"><h2>通知一覧</h2></b-button>
                <!--
                <b-button :to="{ path: '/trap-map' }" block variant="warning" class="text-left mb-3"><h2>ほかパトMap</h2></b-button>
                -->
            </div>
            <!-- ---------------------------------------------- -->
            <div>
                <b-button :to="{ path: '/shot' }" block variant="info" class="text-left mb-3" v-show="dispShot"><h2>もくげき投稿</h2></b-button>
                <b-button :to="{ path: '/map' }" block variant="info" class="text-left mb-3"><h2>もくげきマップ</h2></b-button>
                <b-button :to="{ path: '/timeline' }" block variant="info" class="text-left mb-3"><h2>もくげきリスト</h2></b-button>
            </div>
            <!-- ---------------------------------------------- -->
            <div>
                <b-button :to="{ path: '/logout' }" block variant="dark" class="text-left mb-3"><h2>ログアウト</h2></b-button>
            </div>
            <!-- ---------------------------------------------- -->
            <div class="p-5"></div>
        </b-container>
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "Home",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbUser: {},
            LogoImg: require("@/assets/logo.png"),
            dispShot: false,
            dispHunt: false,
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        //await new Promise(r => setTimeout(r, 300));
        this.isLoading = false;
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                    if (this.dbUser.is_hunter) this.dispHunt = true;
                    if (this.dbUser.is_posting) this.dispShot = true;
                }
            });
        }
        //====================================================
    }
};
</script>
